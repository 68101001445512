import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
import CustomImage from '../customImage/customImage'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'

const ArtistCard = ({ item, theme, isEdit }) => {
  const handleRedirect = (link) => {
    window.open(link)
  }
  const { customNavigate, getTemplateImage, ArtistPlaceholder } = useTemplateHook()

  const handleDetail = (item) => {
    if (isEdit) return
    customNavigate('DIRECT', `/venue/detail/${item._id}`)
  }
  return (
    <div
      style={{ borderColor: theme.c4 }}
      className="shadow w-full border relative  cursor-pointer bg-black flex justify-center items-center"
      onClick={() => handleDetail(item)}
    >
      <CustomImage
        imageUrl={getTemplateImage(item.thumbnail)}
        imgClass="w-full object-contain"
        loaderClass={'h-64'}
        imgAlt={item.name}
        errorPlaceholder={ArtistPlaceholder}
      />
      <div
        style={{ color: theme.c7 }}
        className=" bg-gradient-to-r from-[#d20c83]/75 to-[#0dedf5]/75 bg-opacity-1 w-full absolute bottom-0"
      >
        <p
          className="w-full flex justify-center md:text-base sm:text-xs text-[10px] font-semibold mt-1 md:mt-2 cursor-pointer px-4 line-clamp-1"
          style={{ marginBlockEnd: '1rem' }}
        >
          {item.name}
        </p>
        <div className="flex justify-between sm:p-2 p-1 align-bottom absolute bottom-0 right-0">
          <div className="flex text-xs md:text-sm justify-end space-x-3">
            {item.social_profiles && item.social_profiles?.facebook && (
              <FaFacebookF
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.facebook)}
              />
            )}
            {item.social_profiles && item.social_profiles?.twitter && (
              <FaTwitter
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.twitter)}
              />
            )}
            {item.social_profiles && item.social_profiles?.instagram && (
              <FaInstagram
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.instagram)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ArtistCard)
